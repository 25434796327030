<template lang="pug">
div
  .mt-7.mt-md-10.pb-md-4
    router-link.text-h6.text-decoration-none.font-weight-bold.text-md-h2.mb-6.re-link(to="/services") {{ $t("сервисы") }}
      v-icon.ml-1.icon-large mdi-chevron-right
    div.mt-3
      div.slide-group__wrapper#services-slider
        div.slide-group__content
          div(v-for="(service, index) in services" :key="index")
            v-card.service-card.mr-2.pa-3.rounded-md.pa-md-6.mr-md-4(rounded="xl" outlined @click="navigateTo(service.location, service.tileName, false)")
              v-img.service-icon.mb-4.mb-md-6(eager :src="`/static/${service.icon}`")
              p.font-weight-bold.custom-title {{ service.title }}
              p.pa-0.custom-subtitle.mt-2 {{ service.subtitle }}

  .mt-7.mt-md-10.pb-md-4
    router-link.text-h6.text-decoration-none.font-weight-bold.text-md-h2.mb-6.re-link(to="/marketplaces") {{ $t("маркетплейсы") }}
      v-icon.ml-1.icon-large mdi-chevron-right
    div.mt-3
      div.slide-group__wrapper
        div.slide-group__content
          div(v-for="(marketplace, index) in marketplaces" :key="index")
            v-card.market-card.mr-2.pa-3.rounded-md.pa-md-5.mr-md-4(rounded="xl" outlined @click="navigateTo(marketplace.location, marketplace.tileName, true)")
              .d-flex.justify-space-between.mb-2.mb-md-4.align-center
                p.font-weight-bold.mr-2.mr-md-5.mb-0.mt-1.custom-title {{ marketplace.title }}
                v-img.market-icon(eager :src="`/static/${marketplace.icon}`")
              p.pa-0.mt-4.custom-subtitle {{ marketplace.subtitle }}
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: (vm) => ({
    slider: null,
    isDown: false,
    startX: null,
    scrollLeft: null,
    velX: 0,
    momentumID: null,

    services: [
      {
        title: 'Каспи QR',
        subtitle: vm.$t('прием_оплаты_и_фискализация_чека'),
        icon: 'kaspi-qr-services.png',
        location: '/kkm/kaspi/qr',
        tileName: 'kaspiQR',
      },
      {
        title: 'Halyk Pos',
        subtitle: vm.$t('прием_платежей_картами_с_помощью_телефона'),
        icon: 'halyk-pos-services.png',
        location: '/kkm/halyk/pos',
        tileName: 'halykPos',
      },
      {
        title: 'Freedom Box',
        subtitle: vm.$t('терминал_для_платежей_и_продажи_товаров_в_рассрочку'),
        icon: 'freedom-box-services.png',
        location: '/kkm/freedom/box',
        tileName: 'freedomBox',
      },
      {
        title: 'Freedom Credit',
        subtitle: vm.$t('онлайн_займы_до_млн_тенге_на_карту'),
        icon: 'freedom-credit-services.png',
        location: '/kkm/freedom/credit',
        tileName: 'freedomCredit',
      },
      {
        title: vm.$t('ТИС'),
        subtitle: vm.$t('поможет_увеличить_порог_по_ндс'),
        icon: 'tcis-services.png',
        location: '/kkm/uchet/tcis',
        tileName: 'tcis',
      },
    ],

    marketplaces: [
      {
        title: 'Каспи Магазин',
        subtitle: vm.$t('фискализация_продаж_прямо_из_маркетплейса'),
        icon: 'kaspi-shop-services.png',
        location: '/kkm/kaspi/shop',
        tileName: 'kaspiShop',
      },
      {
        title: vm.$t('подайте_заявку'),
        subtitle: vm.$t('подключи_свой_маркетплейс'),
        icon: 'marketplace-shop-services.png',
        location: '/kkm/marketplace-request',
        tileName: 'marketplaceRequest',
      },
    ],
  }),

  mounted() {
    this.mouseScrollInit()
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
    }),

    navigateTo(location, tileName, byPass) {
      const servicesSlider = document.querySelector('#services-slider')
      if (byPass || servicesSlider.getAttribute('data-slide-dragging') === 'false') {
        this.$router.push(location)
        this.analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: tileName } })
      }
    },

    mouseScrollInit() {
      this.slider = document.querySelector('#services-slider')

      this.slider.addEventListener('mousedown', (e) => {
        this.isDown = true
        this.slider.setAttribute('data-slide-dragging', 'false')
        this.startX = e.pageX - this.slider.offsetLeft
        this.scrollLeft = this.slider.scrollLeft
        this.cancelMomentumTracking()
      })

      this.slider.addEventListener('mouseleave', () => {
        this.isDown = false
      })

      this.slider.addEventListener('mouseup', () => {
        this.isDown = false
        this.beginMomentumTracking()
      })

      this.slider.addEventListener('mousemove', (e) => {
        if (!this.isDown) return
        e.preventDefault()
        const x = e.pageX - this.slider.offsetLeft
        const walk = x - this.startX
        if (walk !== 0) this.slider.setAttribute('data-slide-dragging', 'true')
        const prevScrollLeft = this.slider.scrollLeft
        this.slider.scrollLeft = this.scrollLeft - walk
        this.velX = this.slider.scrollLeft - prevScrollLeft
      })

      this.slider.addEventListener('wheel', () => {
        this.cancelMomentumTracking()
      })
    },

    beginMomentumTracking() {
      this.cancelMomentumTracking()
      this.momentumID = requestAnimationFrame(this.momentumLoop)
    },

    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID)
    },

    momentumLoop() {
      if (!this.slider) return
      this.slider.scrollLeft += this.velX
      this.velX *= 0.95
      if (Math.abs(this.velX) > 0.5) {
        this.momentumID = requestAnimationFrame(this.momentumLoop)
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.icon-large
  font-size 24px
  @media (min-width: 960px)
    font-size 32px

.service-card
  width 160px
  height 160px
  @media (min-width: 960px)
    width 288px
    height 264px

.market-card
  width 180px
  height 140px
  @media (min-width: 960px)
    width 282px
    height 212px

.service-icon
  width 40px
  height 40px
  @media (min-width: 960px)
    width 72px
    height 72px

.market-icon
  width 40px
  height 40px
  @media (min-width: 960px)
    width 72px
    height 72px

.custom-title
  font-size 1rem
  line-height 1.125rem
  @media screen and (min-width: 960px)
    font-size 1.375rem
    line-height 1.625rem

.custom-subtitle
  font-size 0.8125rem
  line-height 1rem
  @media screen and (min-width: 960px)
    font-size 1rem
    line-height 22px

.slide-group__wrapper
  overflow-x auto
  -ms-overflow-style none
  scrollbar-width none
  contain content
  display flex
  flex 1 1 auto

.slide-group__wrapper::-webkit-scrollbar
  display none
  width 0
  background transparent

.slide-group__content
  display flex
  flex 1 0 auto
  position relative
  transition 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
  white-space nowrap
</style>
