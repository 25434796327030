<template lang="pug">
v-container.d-flex.py-0.d-md-flex.mt-md-11.pt-5.pt-md-4.px-lg-16.pb-md-10.flex-column.flex-md-row.flex-nowrap(:class="heightClass")
  .d-flex.flex-column.col-12.px-0.px-md-5.col-sm-8.ma-sm-auto.ma-md-0.col-md-6.py-0.pl-lg-16(style="flex: 1; min-height: 0; height: 100%;")
    .d-flex.flex-column.px-md-4.py-md-5.rounded-xl.mb-3.bg(style="flex: 4; min-height: 0; height: 100%;")
      .d-flex.gap.justify-space-between(style="flex: 1;")
        v-btn.pa-0(icon :width="btnHeight" :height="btnHeight" @click="showPaymentOptionsDialog()")
          v-icon mdi-information-outline
        .d-flex.gap
          v-btn.flex-grow-1.text-md-h5.flex-shrink-0.text-capitalize.text-subtitle-1.py-2.px-4(:height="btnHeight" @click="toDetails(false)") {{ $t("позиции") }}
          v-btn.flex-grow-1.text-md-h5.flex-shrink-0.text-capitalize.text-subtitle-1.py-2.px-4(:height="btnHeight" @click="toJournal()") {{ $t("смена") }}
          v-btn.flex-grow-1.flex-shrink-0.py-1.px-2(v-if="$isCordova()" :height="btnHeight" @click="toDetails(true)")
            v-icon mdi-barcode-scan
      .d-flex.flex-column(style="flex: 4; min-height: 0; height: 100%;")
        .text-h2.text-right.text-md-h1.mb-1.d-flex.align-end.justify-end.re-number-font(style="flex: 1; min-height: 0; overflow-y: auto;") {{ display }}
        p.text-right.text-subtitle-1.text-md-h3.re-number-font(:style="total > 0 ? 'opacity: 1;' : 'opacity: 0;'") {{ total }} ₸
    div(style="flex: 1;")
      v-btn.col-12.mb-3.mb-md-4.mt-md-0.text-md-h3(:height="addPositionBtnHeight" color="reForeground" @click="setCartList([]); $router.push('/uplus/cart'); analyticsLogEvent({ eventName: 're_inventory_add_item_click_kkm' })")
        v-icon.flex-grow-0.mr-2 mdi-plus-box-outline
        .text-subtitle-1.text-md-h3 {{ $t('выбрать_товар_услугу') }}
    v-row.mx-0.mb-2.mb-md-0.gap(style="flex: 1; flex-wrap: nowrap;")
      v-col.pa-0(cols="4" style="flex: 1;")
        v-btn.py-3.px-1.py-md-6(:height="paymentClass" width="100%" :color="paymentType === 'PAYMENT_CASH' ? 'primary' : 'reForeground'" @click="switchPaymentType('PAYMENT_CASH')")
          .d-flex.flex-column.align-center
            v-icon.mb-md-3(:size="iconSize" v-if="$vuetify.breakpoint.mdAndUp") mdi-cash-multiple
            p.text-subtitle-1.text-capitalize.mb-0.text-md-h5 {{ $t('paymentType.PAYMENT_CASH') }}
      v-col.pa-0(cols="4" style="flex: 1;")
        v-btn.py-3.px-1.py-md-6(:height="paymentClass" width="100%" :color="paymentType === 'PAYMENT_CARD' ? 'primary' : 'reForeground'" @click="switchPaymentType('PAYMENT_CARD')")
          .d-flex.flex-column.align-center
            v-icon.mb-md-3(:size="iconSize" v-if="$vuetify.breakpoint.mdAndUp") mdi-credit-card
            p.text-subtitle-1.text-capitalize.mb-0.text-md-h5 {{ $t('paymentType.PAYMENT_CARD') }}
      v-col.pa-0(cols="4" style="flex: 1;")
        v-btn.py-3.px-1.py-md-6(:height="paymentClass" width="100%" :color="paymentType === 'PAYMENT_MOBILE' ? 'primary' : 'reForeground'" @click="switchPaymentType('PAYMENT_MOBILE')")
          .d-flex.flex-column.align-center
            v-icon.mb-md-3(:size="iconSize" v-if="$vuetify.breakpoint.mdAndUp") mdi-cellphone-dock
            p.text-subtitle-1.text-capitalize.mb-0.text-md-h5 {{ $t('paymentType.PAYMENT_MOBILE') }}

  .d-flex.flex-column.col-12.pb-4.pa-0.px-md-5.col-sm-8.ma-sm-auto.ma-md-0.col-md-6.py-md-0.pr-lg-16(style="flex: 1;")
    .d-flex.gap(style="height: 100%;")
      v-col.col-9.col-md-9.col-sm-10.pa-0.d-flex.flex-column.gap
        v-row.gap.ma-0
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('7')") 7
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('8')") 8
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('9')") 9
        v-row.gap.ma-0
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('4')") 4
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('5')") 5
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('6')") 6
        v-row.gap.ma-0
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('1')") 1
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('2')") 2
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('3')") 3
        v-row.gap.ma-0
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="reset()") C
          v-col.pa-0
            v-btn.text-h5.text-md-h3.btn-glass-effect(width="100%" min-width="auto" height="100%" @click="digit('0')") 0
          v-col.pa-0
            v-btn.text-body-1.btn-glass-effect(width="100%" min-height="0" min-width="auto" height="100%" @click="backspace()")
              v-icon mdi-backspace
      v-col.col-3.col-md-3.col-sm-2.d-flex.flex-column.gap.pa-0.flex-shrink-1
        v-btn.flex-grow-1.flex-shrink-0.btn-glass-effect.addon-btn(width="100%" @click="plus()")
          v-icon(size="30") mdi-plus
        v-btn.flex-grow-1.flex-shrink-0.text-md-h3.btn-glass-effect.text-subtitle-1.font-weight-bold.addon-btn(color="secondary" width="100%" @click="next()") {{ $t("далее") }}

  v-dialog(v-model="paymentOptionsDialog" max-width="400")
    v-card.py-0.px-1.rounded-xl(@click="paymentOptionsDialog = false")
      v-card-title
        v-row(row wrap)
          v-col
            .text-title {{ $t("какой_тип_оплаты_выбрать") }}
      v-card-text
        .subtitle-1(v-dompurify-html="paymentOptionsText")
        div.text-center
          a(@click="showChat()") {{ $t("напишите_в_службу_поддержки") }}

</template>
<script>
import fdo from '@comrun/kfdo'
import { Decimal } from 'decimal.js'
import { mapState, mapActions } from 'vuex'
import i18n from '../../i18n/index'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {
  name: 'Calculator',

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    paymentType: 'PAYMENT_CASH',
    stack: [''],
    index: 0,
    display: '0',
    total: 0,

    paymentOptionsDialog: false,
    paymentOptionsText: `
      <span style="color: #fff; ">
      <p class="mb-1">${i18n.t('наличные_налич_если_оплата')}:</p>
      <ul>
        <li>${i18n.t('наличными')}</li>
        <li>${i18n.t('переводом')}</li>
      </ul>
      <p class="mb-1 mt-4">${i18n.t('карта_если_оплата')}:</p>
      <ul>
        <li>${i18n.t('банковской_картой')}</li>
        <li>${i18n.t('через_маркетплейс')}</li>
        <li>${i18n.t('бесконтактным_способом_apple_pay_google_pay_samsung_pay')}</li>
        <li>${i18n.t('в_рассрочку_или_кредит_уточните_в_вашем_банке')}</li>
      </ul>
      <p class="mb-1 mt-4">${i18n.t('мобильный_платеж_если_оплата')}:</p>
      <ul>
        <li>${i18n.t('через_qr_код')}</li>
        <li>${i18n.t('через_удаленную_оплату_выставление_счета')}</li>
      </ul>
      <p class="mb-1 mt-4">${i18n.t('при_перечислении_с_одного_расчетного_счета_на_другой_через_платежное_поручение_и_прочее_чеки_выбивать_не_нужно')}</p>
      <p class="text-center mb-0 mt-4">${i18n.t('остались_вопросы')}</p>
      </span>
    `,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      keyListenerEnabled: state => state.tools.keyListenerEnabled,
    }),

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },

    iconSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '50' : '32'
    },

    heightClass() {
      return this.$vuetify.breakpoint.mdAndUp ? 'height-desktop' : 'height-mobile'
    },

    paymentClass() {
      const mobileHeight = window.innerWidth <= 360 ? '30' : '48'
      return this.$vuetify.breakpoint.mdAndUp ? 'fit-content' : mobileHeight
    },

    btnHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '48' : '33'
    },

    addPositionBtnHeight() {
      const mobileHeight = window.innerWidth <= 360 ? '30' : '48'
      return this.$vuetify.breakpoint.mdAndUp ? '72' : mobileHeight
    },
  },

  watch: {
    paymentOptionsDialog(value) {
      if (!value) {
        this.analyticsLogEvent({ eventName: 're_cash_payment_options_closed' })
      }
    },
  },

  created() {
    if (this.$isFFBMode() && localStorage.getItem('rekassa.kz-ui-defaultPaymentType') === null) {
      this.paymentType = 'PAYMENT_CARD'
    } else {
      this.paymentType = localStorage.getItem('rekassa.kz-ui-defaultPaymentType') || 'PAYMENT_CASH'
    }
  },

  mounted() {
    this.setTicketRequest(null)
    window.addEventListener('keydown', this.keyListener, false)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      setTicketRequest: 'cashRegisters/setTicketRequest',
      setCartList: 'positions/setCartList',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    formatThousends(x) {
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    },

    keyListener(event) {
      if (this.keyListenerEnabled && (this.cashRegister.status === 'REGISTERED' || this.cashRegister.status === 'TRIAL')) {
        const reg = new RegExp('^[0-9\\.]$')
        if (reg.test(event.key)) {
          this.digit(event.key)
        } else if (event.key === 'Backspace') {
          this.backspace()
        } else if (event.key === 'Escape') {
          this.reset()
        } else if (event.key === 'Enter') {
          event.preventDefault()
          this.next()
        } else if (event.key === '+') {
          this.plus()
        }
      }
    },

    toDetails(openScanner) {
      try {
        localStorage.setItem('rekassa.kz-tools-openScanner', openScanner === true ? 'true' : false)
        if (this.total === 0) {
          this.$router.push('/details')
        } else {
          this.prepareTicketRequest()
          this.$router.push('/details')
        }

        if (openScanner) {
          this.analyticsLogEvent({ eventName: 're_positions_barcode_click_button' })
        } else {
          this.analyticsLogEvent({ eventName: 're_positions_click_button' })
        }
      } catch (error) {
        this.$router.push('/details')
      }
    },

    toJournal() {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}`)
      } else {
        this.$router.push(`/shifts/${this.cashRegister.id}`)
      }
      this.analyticsLogEvent({ eventName: 're_shift_click_button' })
    },

    switchPaymentType(type) {
      this.playClickSound()
      this.paymentType = type
    },

    digit(digit) {
      this.playClickSound()
      if ((this.stack[this.index] === '' && digit === '0') || (this.stack[this.index] !== '' && this.stack[this.index].length >= 9)) return
      if (this.stack[this.index] === '') {
        this.stack[this.index] = digit
      } else {
        this.stack[this.index] += digit
      }
      this.prepareDisplay()
    },

    backspace() {
      this.playClickSound()
      if (this.stack[this.index] === '' && this.index > 0) {
        this.index -= 1
        this.stack.pop()
      } else {
        this.stack[this.index] = this.stack[this.index].substring(0, this.stack[this.index].length - 1)
      }
      this.prepareDisplay()
    },

    plus() {
      this.playClickSound()
      if (this.stack[this.index] !== '' && this.stack.length <= 49) {
        this.index += 1
        this.stack[this.index] = ''
        this.prepareDisplay()
      }
    },

    reset() {
      this.playClickSound()
      this.stack = ['']
      this.index = 0
      this.display = '0'
      this.total = 0
    },

    prepareDisplay() {
      this.display = this.stack.reduce((all, item) => {
        if (all === '') {
          // eslint-disable-next-line no-irregular-whitespace
          return item !== '' ? `${this.formatThousends(item)}` : '0'
        }
        // eslint-disable-next-line no-irregular-whitespace
        return item !== '' ? `${all} + ${this.formatThousends(item)}` : `${all} + 0`
      }, '')
      this.total = this.stack.reduce((all, item) => (item !== '' ? new Decimal(all).plus(item).toNumber() : all), 0)
    },

    next() {
      this.playClickSound()
      try {
        if (this.total === 0) {
          this.showSnackbar({ message: this.$t('введите_сумму'), timeout: 2000 })
          return
        }
        this.prepareTicketRequest()
        localStorage.setItem('rekassa.kz-ticket-prepared-from', 'CALCULATOR')
        this.$router.push('/draft')
      } catch (error) {
        // console.debug(`error: ${error}`)
      }
    },

    prepareTicketRequest() {
      const ticketRequest = new fdo.kkm.proto.TicketRequest()

      ticketRequest.operation = fdo.kkm.proto.OperationTypeEnum.OPERATION_SELL
      ticketRequest.domain = new fdo.kkm.proto.TicketRequest.Domain({ type: this.dictionary.domainType[this.preferences.domainType].code })

      this.stack.forEach((item) => {
        if (item !== '') {
          ticketRequest.items.push(this.prepareItem(new Decimal(item).toNumber()))
        }
      })

      const payment = new fdo.kkm.proto.TicketRequest.Payment({
        type: fdo.kkm.proto.PaymentTypeEnum[this.paymentType],
        sum: this.getBillsAndCoinsMoney(this.total),
      })
      ticketRequest.payments.push(payment)

      ticketRequest.amounts = new fdo.kkm.proto.TicketRequest.Amounts({
        total: this.getBillsAndCoinsMoney(this.total),
        taken: this.getBillsAndCoinsMoney(this.paymentType === 'PAYMENT_CASH' ? this.total : 0),
        change: this.getBillsAndCoinsMoney(0),
      })

      this.setTicketRequest(ticketRequest)
    },

    prepareItem(sum) {
      const item = new fdo.kkm.proto.TicketRequest.Item({
        type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY,
        commodity: new fdo.kkm.proto.TicketRequest.Item.Commodity({
          name: this.preferences.defaultItemName,
          sectionCode: '1',
          quantity: 1000,
          price: this.getBillsAndCoinsMoney(sum),
          sum: this.getBillsAndCoinsMoney(sum),
          measureUnitCode: this.unitTypes.find((ut) => ut.value === this.preferences.defaultItemType).code,
          auxiliary: [
            new fdo.kkm.proto.KeyValuePair({
              key: 'UNIT_TYPE',
              value: this.preferences.defaultItemType,
            }),
          ],
        }),
      })

      if (this.configuration.taxMode && this.dictionary.taxType[this.preferences.defaultTaxType].rate >= 0) {
        item.commodity.taxes = [
          new fdo.kkm.proto.TicketRequest.Tax({
            taxType: 100,
            taxationType: this.dictionary.taxationType[this.configuration.taxationType].code,
            percent: new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).times(1000).toNumber(),
            sum: this.getBillsAndCoinsMoney(new Decimal(sum)
              .dividedBy(new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).add(100))
              .times(this.dictionary.taxType[this.preferences.defaultTaxType].rate)
              .toNumber()
              .toFixed(2)),
            isInTotalSum: true,
          }),
        ]
        item.commodity.taxes[0].sum.value = this.commodityTax
      }

      return item
    },

    playClickSound() {
      if (this.$isCordova()) {
        window.nativeclick.trigger()
      }
    },

    showPaymentOptionsDialog() {
      this.paymentOptionsDialog = true
      this.analyticsLogEvent({ eventName: 're_cash_payment_options_showed' })
    },

    showChat() {
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {})
      }
      this.analyticsLogEvent({ eventName: 're_payment_options_helpcrunch_button_click' })
      this.showPaymentOptions = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.height-desktop
  height 600px

.height-mobile
  height calc(100vh - 125px)

@supports (height: calc(env(safe-area-inset-bottom))) {
  .height-mobile {
    height: calc(100vh - 125px - calc(env(safe-area-inset-bottom) / 2));
  }
}

.bg
  position relative
  @media screen and (min-width 960px)
    background var(--v-reForeground-base)

.addon-btn.v-btn:not(.v-btn--round).v-size--default
  min-width auto !important
  padding initial !important

.gap
  gap 8px
</style>
