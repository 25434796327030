<template lang="pug">
v-dialog(v-model="cashRegisterSwitch" v-if="cashRegisterSwitch" scrollable persistent :fullscreen="$vuetify.breakpoint.xsOnly" :hide-overlay="$vuetify.breakpoint.xsOnly" width="600" :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'")
  v-card
    v-card-title.pa-0
      v-toolbar.new-cash-register-toolbar(dense flat)
        v-progress-linear(:active="cashRegisterItemListLoading" :indeterminate="cashRegisterItemListLoading" absolute bottom)
        v-btn(icon @click="showCashRegisterSwitch(false)")
          v-icon mdi-close
        v-spacer
        | {{ $t("список_касс") }}
        v-spacer
        v-btn(id="options_button" icon)
          v-icon mdi-dots-vertical
          v-menu(bottom left activator="#options_button")
            v-list
              v-list-item(@click="toRestore()")
                v-list-item-content
                  v-list-item-subtitle {{ $t("подключить_ранее_активированную_кассу") }}
              v-list-item(@click="manualActivation()")
                v-list-item-content
                  v-list-item-subtitle {{ $t("регистрация_через_кабинет_офд") }}
              v-list-item(@click="assistant()")
                v-list-item-content
                  v-list-item-subtitle ASSISTANT
              v-list-item(@click="oldRegTest()")
                v-list-item-content
                  v-list-item-subtitle OLD REG TEST
              v-list-item(@click="newRegTest()")
                v-list-item-content
                  v-list-item-subtitle NEW REG TEST
              v-list-item(@click="newRegDev()")
                v-list-item-content
                  v-list-item-subtitle NEW REG DEV

      v-expansion-panels.new-cash-register-expansion-panels(v-model="newExpansionPanels" multiple flat tile hover )
        v-expansion-panel
          v-btn(text block height="48" @click="showCashRegisterSwitch(false); $router.push('/miniapp?name=ofd')")
            v-icon(left) mdi-plus
            | {{ $t("новая_касса") }}
          v-expansion-panel-content
            v-form(ref="newCashRegisterNameForm" @submit.prevent="createCashRegister()")
              v-text-field(v-model="newCashRegisterName" ref="newCashRegisterName" :disabled="newCashRegisterLoading" :rules="[rules.required]" autocomplete="off" counter="40" maxlength="40" clearable :placeholder="$t('название_кассы')")
              v-btn.mt-2(color="primary" small block :loading="newCashRegisterLoading" @click="createCashRegister()") {{ $t("ок") }}

    v-divider

    v-card-text.pa-0.other-cash-register-expansion-panels
      v-expansion-panels(v-model="otherExpansionPanels" multiple flat tile hover)
        v-expansion-panel(:class="{'d-none' : pendingInvitationsList.length === 0 }")
          v-expansion-panel-header.v-subheader {{ $t("доступы_к_другим_кассам") }}
          v-expansion-panel-content
            v-list-item(v-for="item in pendingInvitationsList" :key="item.cashRegister.serialNumber")
              template(v-slot:default="{ active, toggle }")
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.serialNumber }}
                  v-list-item-subtitle.text--primary
                    span(v-if="item.cashRegister.name") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.organization.businessName }}
                  v-list-item-subtitle.mt-3
                    v-btn(rounded small color="secondary" :loading="acceptInvitationLoading" :disabled="rejectInvitationLoading" @click="acceptCRInvitation(item)" ) {{ $t("принять_приглашение") }}
                    v-btn.ml-4(rounded small color="error" :loading="rejectInvitationLoading" :disabled="acceptInvitationLoading" @click="rejectCRInvitation(item.id)") {{ $t("отказаться") }}
                  v-list-item-subtitle.text--primary.mt-3.mb-1
                    v-icon.mr-2(small color="secondary") mdi-phone
                    a(@click.prevent="$openLink(`https://wa.me/${item.owner.phone}?text=${$t('здравствуйте_вы_отправили_мне_приглашение', { serial: item.cashRegister.serialNumber, name: item.cashRegister.name, company: `${item.organization.businessName} - ${item.organization.businessId}` })}`, '_system')") {{ $t("связаться_с_приглашающим") }}

        v-expansion-panel(:class="{'d-none' : cashRegisterItemListNew.length === 0 }")
          v-expansion-panel-header.v-subheader {{ $t("новые_ожидают_завершения") }} ({{ cashRegisterItemListNew.length }})
          v-expansion-panel-content
            v-list-item(v-for="item in cashRegisterItemListNew" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
              template(v-slot:default="{ active, toggle }")
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.serialNumber }}
                  v-list-item-subtitle
                    span(v-if="item.cashRegister.name") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.cashRegister.createDate | moment("calendar") }}
                  v-list-item-subtitle
                    // Старая регистрация
                    template(v-if="isOldRegisteration(item.cashRegister)")
                      v-btn.mt-2(color="primary" small :disabled="cashRegisterItemListLoading" @click="showCashRegisterSwitch(false); $router.push(`/ofdreg?cashRegisterId=${item.cashRegister.id}`)") {{ $t('продолжить_регистрацию') }}
                      v-menu(offset-y='')
                        template(v-slot:activator='{ on, attrs }')
                          v-btn.mt-2.ml-5(icon small v-bind='attrs' v-on='on')
                            v-icon mdi-dots-vertical
                        v-list
                          v-list-item(@click="toActivation(item)")
                            v-list-item-title {{ $t("активировать") }}
                          v-list-item(@click="deleteNewCashRegisterConfirm(item.cashRegister)")
                            v-list-item-title {{ $t("удалить") }}
                    // Новая регистрация
                    template(v-else-if="isNewRegisteration(item.cashRegister)")
                      v-btn.mt-2(color="primary" small :disabled="cashRegisterItemListLoading" @click="showCashRegisterSwitch(false); $router.push(`/miniapp?name=ofd&cashRegisterId=${item.cashRegister.id}`)") {{ $t('продолжить_регистрацию') }}
                      v-menu(offset-y='')
                        template(v-slot:activator='{ on, attrs }')
                          v-btn.mt-2.ml-5(icon small v-bind='attrs' v-on='on')
                            v-icon mdi-dots-vertical
                        v-list
                          v-list-item(@click="toActivation(item)")
                            v-list-item-title {{ $t("активировать") }}
                          v-list-item(@click="deleteNewCashRegisterConfirm(item.cashRegister)")
                            v-list-item-title {{ $t("удалить") }}
                    // Ручное активирование
                    template(v-else)
                      v-btn.mt-2(color="primary" small :disabled="cashRegisterItemListLoading" @click="toActivation(item)") {{ $t("активировать") }}
                      v-btn.mt-2.ml-3(icon @click="copy(item.cashRegister.serialNumber)" :disabled="cashRegisterItemListLoading")
                        v-icon(small) mdi-content-copy
                      v-btn.mt-2(icon @click="deleteNewCashRegisterConfirm(item.cashRegister)" :disabled="cashRegisterItemListLoading")
                        v-icon(small) mdi-delete

        v-expansion-panel(:class="{'d-none' : cashRegisterItemListRegistered.length === 0 }")
          v-expansion-panel-header.v-subheader {{ $t("на_учёте_в_налоговой_активные") }} ({{ cashRegisterItemListRegistered.length }})
          v-expansion-panel-content
            template(v-if="cashRegistersViewMode === 'LIST'")
              v-list-item(@click="switchCashRegister(item)" v-for="item in cashRegisterItemListRegistered" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
                template(v-slot:default="{ active, toggle }")
                  v-list-item-content
                    v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber || item.cashRegister.serialNumber }}
                    v-list-item-subtitle
                      span(v-if="(item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber)") {{ item.cashRegister.serialNumber }} •&nbsp;
                      | {{ item.organization.businessName }}
                    v-list-item-subtitle {{ $t("роль") }}: {{ $t('role.' + item.roles[0]) }}
                  v-list-item-action
                    v-chip.font-weight-bold(small :color="$t(`cashRegister.color.${cashRegisterStatus(item.cashRegister)}`)") {{ $t(`cashRegister.status.${cashRegisterStatus(item.cashRegister)}`) }}
                      span(v-if="item.cashRegister.fdoMode === 'OFFLINE' && hoursLeftTillBlocked(item.cashRegister) >= 0") &nbsp; ({{ hoursLeftTillBlocked(item.cashRegister) }} {{ $t("час") }})
            template(v-else)
              v-treeview.re-treeview(open-on-click :items="cashRegisterItemListRegisteredTreeview" :open.sync="treeViewState")
                template(v-slot:label="{ item, leaf }")
                  div.subtitle-1(v-if="!leaf") {{ item.name }}
                  div(v-else)
                    v-list-item(@click="switchCashRegister(item)" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
                      template(v-slot:default="{ active, toggle }")
                        v-list-item-content
                          v-list-item-title.subtitle-1 {{ item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber || item.cashRegister.serialNumber }}
                          v-list-item-subtitle
                            span(v-if="(item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber)") {{ item.cashRegister.serialNumber }} •&nbsp;
                            | {{ $t("роль") }}: {{ $t('role.' + item.roles[0]) }}
                        v-list-item-action
                          v-chip.font-weight-bold(small :color="$t(`cashRegister.color.${cashRegisterStatus(item.cashRegister)}`)") {{ $t(`cashRegister.status.${cashRegisterStatus(item.cashRegister)}`) }}
                            span(v-if="item.cashRegister.fdoMode === 'OFFLINE' && hoursLeftTillBlocked(item.cashRegister) >= 0") &nbsp; ({{ hoursLeftTillBlocked(item.cashRegister) }} {{ $t("час") }})

        v-expansion-panel(:class="{'d-none' : cashRegisterItemListTrial.length === 0 }")
          v-expansion-panel-header.v-subheader {{ $t("демонстрационные") }}
          v-expansion-panel-content
            v-list-item(@click="switchCashRegister(item)" v-for="item in cashRegisterItemListTrial" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
              template(v-slot:default="{ active, toggle }")
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber || item.cashRegister.serialNumber }}
                  v-list-item-subtitle
                    span(v-if="(item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber)") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.organization.businessName }}

        v-expansion-panel
          v-expansion-panel-header.v-subheader(@click="fetchCashRegisterDeregisteredList()") {{ $t("архив_деактивированные") }} {{ cashRegisterItemListDeregistered ? `(${cashRegisterItemListDeregistered.length})` : '' }}
            v-progress-linear(:active="cashRegisterDeregisteredLoading" :indeterminate="cashRegisterDeregisteredLoading" absolute bottom)
          v-expansion-panel-content
            v-list-item(@click="switchCashRegister(item)" v-for="item in cashRegisterItemListDeregistered" :key="item.cashRegister.serialNumber" :class="{'active-v-list-item': item.cashRegister.id == cashRegister.id}")
              template(v-slot:default="{ active, toggle }")
                v-list-item-content
                  v-list-item-title.title {{ item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber || item.cashRegister.serialNumber }}
                  v-list-item-subtitle
                    span(v-if="(item.cashRegister.name || item.cashRegister.pos.title || item.cashRegister.registrationNumber)") {{ item.cashRegister.serialNumber }} •&nbsp;
                    | {{ item.organization.businessName }}
                  v-list-item-subtitle {{ item.cashRegister.registrationDate | moment("ll") }} - {{ item.cashRegister.deregistrationDate | moment("ll") }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    cashRegisterItemListLoading: false,
    cashRegisterDeregisteredLoading: false,

    otherExpansionPanels: [],

    newExpansionPanels: [],
    newCashRegisterName: '',
    newCashRegisterLoading: false,

    pendingInvitationsList: [],
    acceptInvitationLoading: false,
    rejectInvitationLoading: false,

    cashRegistersViewMode: 'LIST',
    treeViewState: [],
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      organization: state => state.cashRegisters.cashRegister.organization,
      cashRegisterSwitch: state => state.tools.cashRegisterSwitch,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      cashRegisterItemList: state => state.cashRegisters.cashRegisterItemList,
      cashRegisterItemListRegistered: state => state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'REGISTERED')?.sort((a, b) => a.cashRegister.name.localeCompare(b.cashRegister.name)),
      cashRegisterItemListRegisteredTreeview: state => {
        const organizationMap = {}

        state.cashRegisters.cashRegisterItemList
          .filter(item => item.cashRegister.status === 'REGISTERED')
          .forEach(cashRegisterData => {
            const { cashRegister, organization } = cashRegisterData
            const posTitle = cashRegister.pos.address
            const orgBusinessId = organization.businessId

            if (!organizationMap[orgBusinessId]) {
              organizationMap[orgBusinessId] = {
                id: orgBusinessId,
                name: organization.businessName.toUpperCase(),
                children: {},
              }
            }

            if (!organizationMap[orgBusinessId].children[posTitle]) {
              organizationMap[orgBusinessId].children[posTitle] = {
                id: posTitle,
                name: posTitle,
                children: [],
              }
            }

            organizationMap[orgBusinessId].children[posTitle].children.push({
              ...cashRegisterData,
              id: cashRegisterData.cashRegister.id,
              name: cashRegisterData.cashRegister.name,
            })
          })

        const organizedCashRegisters = Object.values(organizationMap).map(org => ({
          ...org,
          children: Object.values(org.children),
        }))

        organizedCashRegisters.sort((a, b) => a.name.localeCompare(b.name))
        organizedCashRegisters.forEach(item => {
          if (item.children) {
            item.children.sort((a, b) => a.name.localeCompare(b.name))
            item.children.forEach(child => {
              if (child.children) {
                child.children.sort((a, b) => a.name.localeCompare(b.name))
              }
            })
          }
        })

        return organizedCashRegisters
      },
      cashRegisterItemListNew: state => state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'NEW')?.sort((a, b) => a.cashRegister.createDate > b.cashRegister.createDate),
      cashRegisterItemListTrial: state => state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'TRIAL')?.sort((a, b) => a.cashRegister.name.localeCompare(b.cashRegister.name)),
      cashRegisterItemListDeregistered: state => state.cashRegisters.cashRegisterDeregisteredItemList?.sort((a, b) => a.cashRegister.name.localeCompare(b.cashRegister.name)),
      cashRegisterTreeViewState: state => state.cashRegisters.cashRegisterTreeViewState,
    }),
  },

  watch: {
    cashRegisterSwitch(val) {
      if (val) {
        this.init()
        this.setKeyListenerStatus(false)
      } else {
        this.setKeyListenerStatus(true)
      }
    },

    treeViewState(val) {
      this.setCashRegisterTreeViewState(val)
    },
  },

  methods: {
    ...mapActions({
      selectCashRegister: 'cashRegisters/selectCashRegister',
      fetchUserCashRegisterList: 'cashRegisters/fetchUserCashRegisterList',
      fetchUserCashRegisterDeregisteredList: 'cashRegisters/fetchUserCashRegisterDeregisteredList',
      fetchUserPendingInvitations: 'cashRegisters/fetchUserPendingInvitations',
      createNewCashRegister: 'cashRegisters/createNewCashRegister',
      deleteNewCashRegister: 'cashRegisters/deleteNewCashRegister',
      acceptInvitation: 'cashRegisters/acceptInvitation',
      rejectInvitation: 'cashRegisters/rejectInvitation',
      setCashRegisterPinChecked: 'cashRegisters/setCashRegisterPinChecked',
      setCashRegisterTreeViewState: 'cashRegisters/setCashRegisterTreeViewState',
      showMiniApp: 'miniapp/show',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      copyToClipboard: 'tools/copyToClipboard',
      setKeyListenerStatus: 'tools/setKeyListenerStatus',
      analyticsLogEvent: 'analytics/logEvent',
      clearPositions: 'positions/clear',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      setGlobalLoading: 'tools/setGlobalLoading',
    }),

    hoursLeftTillBlocked(cashRegister) {
      return cashRegister && cashRegister.offlineExpireTime ? Math.floor(this.$moment.duration(this.$moment(cashRegister.offlineExpireTime).diff(new Date())).asHours()) : null
    },

    init() {
      this.cashRegistersViewMode = localStorage.getItem('rekassa.kz-ui-сash-registers-view-mode') || (this.cashRegisterItemListRegistered.length > 20 ? 'TREE' : 'LIST')
      this.treeViewState = [...this.cashRegisterTreeViewState, this.organization.businessId, this.cashRegister.pos.address]

      this.pendingInvitationsList = []
      this.newExpansionPanels = []
      this.otherExpansionPanels = [1, 2]
      if (this.cashRegisterItemListTrial.length > 0) {
        this.otherExpansionPanels = [1, 2, 3]
      }
      this.cashRegisterItemListLoading = true
      this.fetchUserCashRegisterList().then(() => {
        this.fetchUserPendingInvitations().then((result) => {
          this.pendingInvitationsList = result.data._embedded.invitations
          if (this.pendingInvitationsList.length > 0) {
            this.otherExpansionPanels.push(0)
          }
          this.cashRegisterItemListLoading = false
        }).catch(() => {
          this.cashRegisterItemListLoading = false
        })
      }).catch(() => {
        this.cashRegisterItemListLoading = false
      })

      this.$nextTick(() => {
        const element = document.getElementsByClassName('active-v-list-item')[0]
        if (element) element.scrollIntoView({ block: 'center', behavior: 'auto' })
      })
    },

    toRestore() {
      this.showCashRegisterSwitch(false)
      this.$router.push('/kkm/restore')
      this.analyticsLogEvent({ eventName: 're_cash_restore_click_button' })
    },

    toActivation(item) {
      this.showCashRegisterSwitch(false)
      this.setKeyListenerStatus(true)
      this.$router.push(`/kkm/activation/${item.cashRegister.id}`)
      this.analyticsLogEvent({ eventName: 're_cash_activate_click_button' })
    },

    fetchCashRegisterDeregisteredList() {
      this.setCashRegisterTreeViewState([])
      if (!this.otherExpansionPanels.includes(4)) {
        this.cashRegisterDeregisteredLoading = true
        this.fetchUserCashRegisterDeregisteredList().then(() => {
          this.otherExpansionPanels.push(4)
          this.cashRegisterDeregisteredLoading = false
        }).catch(() => {
          this.cashRegisterDeregisteredLoading = false
        })
      }
    },

    switchCashRegister(item) {
      this.setGlobalLoading(true)
      this.selectCashRegister(item.cashRegister.id).then(() => {
        this.setCashRegisterPinChecked(false)
        this.clearPositions()
        this.setGlobalLoading(false)
      }).catch(() => {
        this.setGlobalLoading(false)
      })
      this.showCashRegisterSwitch(false)
    },

    createCashRegister() {
      this.newCashRegisterName = this.newCashRegisterName.trim()
      if (this.$refs.newCashRegisterNameForm.validate() && this.newCashRegisterName.length > 0) {
        this.newCashRegisterLoading = true
        const form = new FormData()
        form.append('name', this.newCashRegisterName)
        this.createNewCashRegister({ data: form }).then(() => {
          this.fetchUserCashRegisterList().then(() => {
            this.newCashRegisterLoading = false
            setTimeout(() => {
              this.newExpansionPanels = []
              this.otherExpansionPanels = [1]
              this.newCashRegisterName = ''
              this.$refs.newCashRegisterNameForm.reset()
            }, 200)
            this.analyticsLogEvent({ eventName: 're_cash_register_created' })
          }).catch(() => {
            this.newCashRegisterLoading = false
          })
        }).catch((error) => {
          if (error && error.response && error.response.data && error.response.data.code === 'CASH_REGISTER_NOT_REGISTERED_LIMIT') {
            this.showSnackbar({ message: this.$t('backend/CASH_REGISTER_NOT_REGISTERED_LIMIT', { limit: error.response.data.meta.LIMIT }) })
          }
          this.newCashRegisterLoading = false
        })
      }
    },

    deleteNewCashRegisterConfirm(cashRegister) {
      this.showConfirm({
        title: this.$t('вы_действительно_хотите_удалить_кассу', { serialNumber: cashRegister.serialNumber }),
        resolveText: this.$t('удалить'),
        rejectText: this.$t('отменить'),
        resolveButtonColor: 'error',
        rejectButtonColor: 'gray',
      }).then(() => {
        this.cashRegisterItemListLoading = true
        // eslint-disable-next-line object-shorthand
        this.deleteNewCashRegister({ сashRegister: cashRegister }).then(() => {
          this.fetchUserCashRegisterList().then(() => {
            this.cashRegisterItemListLoading = false
          }).catch(() => {
            this.cashRegisterItemListLoading = false
          })
        }).catch(() => {
          this.cashRegisterItemListLoading = false
        })
      }).catch(() => {})
    },

    acceptCRInvitation(invitation) {
      this.acceptInvitationLoading = true
      this.acceptInvitation({ id: invitation.id }).then(() => {
        this.acceptInvitationLoading = false
        this.switchCashRegister(invitation)
        this.showSnackbar({ message: this.$t('приглашение_успешно_принято') })
      }).catch(() => {
        this.acceptInvitationLoading = false
      })
    },

    rejectCRInvitation(id) {
      this.rejectInvitationLoading = true
      this.rejectInvitation({ id }).then(() => {
        this.rejectInvitationLoading = false
        this.init()
      }).catch(() => {
        this.rejectInvitationLoading = false
      })
    },

    manualActivation() {
      this.newExpansionPanels = [0]
    },

    cashRegisterStatus(cashRegister) {
      if (cashRegister.status === 'TRIAL' || cashRegister.status === 'DEREGISTERED') {
        return cashRegister.status
      } if (cashRegister.status === 'REGISTERED') {
        return cashRegister.fdoMode
      } return null
    },

    copy(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },

    isOldRegisteration(cashRegister) {
      return cashRegister.data.preferences.app_ofd
    },

    isNewRegisteration(cashRegister) {
      return ['IN_PROGRESS', 'SENT', 'ERROR'].includes(cashRegister?.data?.preferences?.ofd?.reg?.status?.code)
    },

    assistant() {
      this.showMiniApp({
        url: 'https://ml.apps-test.rekassa.kz',
        name: 'assistant-miniapp',
      })
    },

    oldRegTest() {
      this.$router.push('/ofdreg')
    },

    newRegTest() {
      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_OFD_URL,
        name: process.env.VUE_APP_MINIAPP_OFD_NAME,
        params: [
          { mode: 'reg' },
        ],
      })
    },

    newRegDev() {
      this.showMiniApp({
        url: 'https://ofd.apps-dev.rekassa.kz',
        name: process.env.VUE_APP_MINIAPP_OFD_NAME,
        params: [
          { mode: 'reg' },
        ],
      })
    },
  },
}
</script>
<style lang="stylus">
.new-cash-register-expansion-panels
  .v-expansion-panel--active > .v-expansion-panel-header
    min-height auto !important
.other-cash-register-expansion-panels
  .v-expansion-panel-content__wrap
    padding 0 !important
  .v-expansion-panel--active, .v-expansion-panel--active + .v-expansion-panel
    margin-top 0 !important
  .v-expansion-panel--active > .v-expansion-panel-header
    min-height auto !important
.new-cash-register-toolbar
  .v-toolbar__content
    padding-left 4px !important

.re-treeview
  .v-treeview-node__label
    white-space normal !important
  .v-treeview-node__root
    margin-bottom 6px !important
  .v-treeview-node__level
    width 10px !important
  .v-list-item__title
    white-space normal !important
</style>
