<template lang="pug">
.mt-7.mt-md-10.pb-md-4
  .text-h6.text-decoration-none.font-weight-bold.text-md-h2.mb-3 {{ $t("акции") }}
  section#image-carousel.splide
    .splide__track
      ul.splide__list
        li.splide__slide(v-for="(item, $index) in promotionsJSON" :key="'p_' + $index")
          img(:src='item.src' @click="$openLink(item.link, '_system')")
</template>
<script>
import '@splidejs/splide/dist/css/splide.min.css'
import Splide from '@splidejs/splide'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../../i18n'

export default {
  data: () => ({
    promotionsJSON: null,
    splide: null,
    interval: null,
  }),

  computed: {
    ...mapState({

    }),

    locale() {
      return getLocale()
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    '$vuetify.breakpoint.mdAndUp': function (value) {
      const perPage = value ? 2 : 1
      this.splide.options = { perPage, gap: 20, type: 'loop' }
    },
  },

  mounted() {
    this.firebaseRemoteConfigGetString('PROMOTIONS').then((result) => {
      try {
        this.promotionsJSON = JSON.parse(result)[this.locale]
        this.$nextTick(() => {
          const perPage = this.$vuetify.breakpoint.mdAndUp ? 2 : 1
          this.splide = new Splide('.splide', { perPage, gap: 20, type: 'loop' }).mount()
          if (this.interval === null) {
            this.interval = setInterval(() => this.nextSplide(), 10000)
          }
        })
      } catch (error) {
        // console.debug(error)
      }
    })
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    nextSplide() {
      if (this.splide.length - 1 === this.splide.index) {
        this.splide.go(0)
      } else {
        this.splide.go('>')
      }
    },
  },
}
</script>
<style lang="stylus">
.splide__slide
  // margin-right 1rem
.splide__slide img
  width 100%
  height auto
  border-radius 12px
.splide__arrows
  display none!important
.v-application ul.splide__pagination
  padding 0 6px
.splide__pagination
  left 18px!important
  right: unset!important
  bottom 14px!important
  padding-left 0
  border-radius 12px
  height 24px
@media(max-width 959px)
  .splide
    max-width 368px
.splide__pagination__page
  background var(--v-reBackground-base) !important
.splide__pagination__page.is-active
  background #fff !important
  transform none!important
.splide__pagination li
  line-height 0!important
</style>
