<template lang="pug">
div
  re-stories

  v-container.mt-1.mt-md-8.mb-3
    re-shift-miniapps

    re-analytics

    re-services-marketplaces

    //- re-promotions

  re-anketolog

</template>
<script>
import { mapActions } from 'vuex'
import Stories from './parts/Stories.vue'
import ShiftMiniApps from './parts/ShiftMiniApps.vue'
import Promotions from './parts/Promotions.vue'
import Anketolog from './parts/Anketolog.vue'
import Analytics from './parts/Analytics.vue'
import ServicesMarketplaces from './parts/ServicesMarketplaces.vue'

export default {
  components: {
    're-stories': Stories,
    're-shift-miniapps': ShiftMiniApps,
    're-analytics': Analytics,
    're-services-marketplaces': ServicesMarketplaces,
    're-promotions': Promotions,
    're-anketolog': Anketolog,
  },

  beforeRouteEnter(to, from, next) {
    // Если FreedomBox или Мой учет iframe то перенаправляем на /kkm
    if (process.env.VUE_APP_FFB_POS_MODE === 'true' || window.location !== window.parent.location) {
      next('/kkm')
    } else {
      next()
    }
  },

  mounted() {
    this.analyticsLogEvent({ eventName: 're_dash_opened' })
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
    }),
  },
}
</script>
<style scoped lang="stylus">
</style>
