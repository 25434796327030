<template lang="pug">
v-dialog(v-model="globalLoading" persistent overlay-opacity="0" content-class="elevation-0")
  v-container.d-flex.justify-center.py-5
    v-progress-circular(indeterminate)
</template>
<script>
import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState({
      globalLoading: state => state.tools.globalLoading,
    }),
  },
}
</script>
