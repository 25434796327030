import { render, staticRenderFns } from "./RegistrationStatus.vue?vue&type=template&id=c54e4858&scoped=true&lang=pug&"
import script from "./RegistrationStatus.vue?vue&type=script&lang=js&"
export * from "./RegistrationStatus.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationStatus.vue?vue&type=style&index=0&id=c54e4858&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c54e4858",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VIcon,VImg})
