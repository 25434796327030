<template lang="pug">
v-dialog(v-model="showDialog" persistent max-width="500")
  v-card
    v-card-text(style="min-height: 150px")
      v-form(ref="form")
        v-row
          v-col.pb-0
            template(v-if="preferences.logoUrl")
              v-row
                v-col
                  v-img(:src="preferences.logoUrl" contain style="max-height: 100px;")
            template(v-else)
              template(v-if="image.src")
                cropper(ref="cropper" :src="image.src" check-orientation image-restriction="none" default-boundaries="fill" :canvas="{minHeight: 104, minWidth: 104, maxHeight: 4096, maxWidth: 4096, width: 256}")
                div.vertical-buttons
                  div.square-button(@click="zoom(1.3)")
                    v-icon mdi-magnify-plus-outline
                  div.square-button(@click="zoom(0.7)")
                    v-icon mdi-magnify-minus-outline
              template(v-else)
                v-file-input(v-model="file" ref="upload" :label="$t('выберите_логотип_для_загрузки')" accept="image/*" outlined dense @change="loadImage($event)")
                v-alert(type="info" text) {{ $t("для_корректного_отображения_логотипа_на_темной_теме_загружайте_логотип_с_прозрачным_фоном_в_формате") }}

    v-divider
    v-card-actions

      v-btn(v-if="preferences.logoUrl" color="error" @click="deleteLogo()" :loading="deleteLoading") {{ $t("удалить") }}
      v-spacer
      v-btn(outlined @click="showDialog = false" :disabled="uploadLoading") {{ $t("закрыть") }}
      v-btn(v-if="!preferences.logoUrl" color="primary" @click="uploadLogo()" :loading="uploadLoading") {{ $t("загрузить") }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    cropper: Cropper,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      file: null,

      image: {
        src: null,
        type: null,
      },

      uploadLoading: false,
      deleteLoading: false,
    }
  },

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),
  },

  watch: {
    value(value) {
      if (value) {
        this.showDialog = true
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
          this.image = { src: null, type: null }
          this.file = null
        }
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
  },

  methods: {
    ...mapActions({
      uploadImage: 'tools/uploadImage',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      savePreferences: 'cashRegisters/savePreferences',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    loadImage(file) {
      if (file) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        const blob = URL.createObjectURL(file)
        const reader = new FileReader()
        reader.onload = () => {
          this.image = {
            src: blob,
            type: this.getMimeType(file, file.type),
          }
        }
        reader.readAsArrayBuffer(file)
      }
    },

    uploadLogo() {
      if (this.image.src) {
        this.uploadLoading = true
        const { canvas } = this.$refs.cropper.getResult()
        const form = new FormData()
        form.append('image', canvas.toDataURL().replace(/^data:image\/[a-z]+;base64,/, ''))
        this.uploadImage({ data: form }).then((result) => {
          if (result && result.data && result.data.data && result.data.data.url) {
            this.preferences.logoUrl = result.data.data.url
            this.save()
          }
        }).catch((error) => {
          this.uploadLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }
    },

    deleteLogo() {
      this.deleteLoading = true
      this.preferences.logoUrl = null
      this.save()
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src)
        this.image = { src: null, type: null }
        this.file = null
      }
    },

    save() {
      this.savePreferences({
        cashRegister: this.cashRegister,
        preferences: this.preferences,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id)
        this.uploadLoading = false
        this.deleteLoading = false
      }).catch((error) => {
        this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
        this.selectCashRegister(this.cashRegister.id)
        this.uploadLoading = false
        this.deleteLoading = false
      })
    },

    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },

    getMimeType(file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4)
      let header = ''
      for (let i = 0; i < byteArray.length; i = +1) {
        header += byteArray[i].toString(16)
      }
      switch (header) {
        case '89504e47':
          return 'image/png'
        case '47494638':
          return 'image/gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg'
        default:
          return fallback
      }
    },
  },
}
</script>

<style lang="stylus">
.vertical-buttons
  position absolute
  left 10px
  top 50%
  transform translateY(-50%)
.square-button
  background: rgba(black, 0.4)
  display: flex
  align-items: center
  justify-content: center
  height: 42px
  width: 42px
  margin-bottom: 10px
  cursor: pointer
  transition: background 0.5s
  &:hover
    background: black
</style>
