<template lang="pug">
div
  .font-weight-bold.text-subtitle-1.text-lg-h5(v-if="cashRegister.status === 'TRIAL'" @click="determineAction()")
      div.d-flex.align-center.justify-center
        div.text-no-wrap.re-cursor-pointer(:class="{'re-switch-hover': $vuetify.breakpoint.mdAndUp}")
          v-icon.mr-2 mdi-information-outline
          span(v-dompurify-html="$t(`fdoRegistrationStatus.${registeringCashRegister.status}`)")
          v-icon.ml-1 mdi-chevron-right
        .re-register-blur
  .d-flex.align-center.font-weight-bold.text-subtitle-1.text-lg-h5.px-4.re-cursor-pointer.re-switch-hover(v-else text @click="cashRegisterSwitch()")
    div.d-flex.align-center
      template(v-if="hasSuccessStatus")
        v-img(src="/static/icons/popper.svg" width="40").mr-4
        span {{ $t('Поздравляем! Ваша касса зарегистрирована!') }}
        v-icon.ml-1 mdi-chevron-right
        .re-register-blur-success
      template(v-else)
        span {{ cashRegister.name ? cashRegister.name : organization.businessName }}
        v-icon.ml-1 mdi-chevron-right
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,

      registeringCashRegister: state => {
        // Фильтруем список касс по статусу и наличию статуса регистрации
        const newList = state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'NEW' && item.cashRegister.data.preferences?.ofd?.reg?.status?.code)

        // Если есть одна касса с новым статусом
        if (newList.length === 1) {
          return {
            status: newList[0].cashRegister.data.preferences.ofd.reg.status.code,
            cashRegisterId: newList[0].cashRegister.id,
          }
        }

        // Если есть несколько касс с новым статусом
        if (newList.length > 0) {
          return {
            status: 'MANY',
          }
        }

        // Если нет касс с новым статусом
        return {
          status: 'NEW',
        }
      },

      // Проверка на успешный статус регистрации
      hasSuccessStatus: state => state.cashRegisters.cashRegister.cashRegister.data.preferences?.ofd?.reg?.status?.code === 'SUCCESS',
    }),
  },

  methods: {
    ...mapActions({
      showMiniApp: 'miniapp/show',
      savePreferences: 'cashRegisters/savePreferences',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    determineAction() {
      if (['NEW', 'IN_PROGRESS', 'SENT', 'ERROR'].includes(this.registeringCashRegister.status)) {
        this.openMiniApp()
      } else if (this.registeringCashRegister.status === 'MANY') {
        this.showCashRegisterSwitch(true)
      }
      this.analyticsLogEvent({ eventName: 're_cash_activate_block_click_button' })
    },

    openMiniApp() {
      const params = [
        { mode: 'reg' },
      ]
      if (this.registeringCashRegister.cashRegisterId) {
        params.push({ cashRegisterId: this.registeringCashRegister.cashRegisterId })
      }

      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_OFD_URL,
        name: process.env.VUE_APP_MINIAPP_OFD_NAME,
        params,
      })
    },

    cashRegisterSwitch() {
      if (this.hasSuccessStatus) {
        this.preferences.ofd.reg.status.code = 'FINISHED'
        this.save()
      }

      this.showCashRegisterSwitch(true)
      this.analyticsLogEvent({ eventName: 're_nav_cash_register_switch_clicked' })
    },

    save() {
      this.savePreferences({
        cashRegister: this.cashRegister,
        preferences: this.preferences,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id)
      }).catch((error) => {
        this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
        this.selectCashRegister(this.cashRegister.id)
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
.re-register-blur
  background radial-gradient(55.83% 335% at 50.22% 236.67%, #FF1957 0%, rgba(255, 25, 87, 0) 100%)
  position absolute
  width 459px
  height 100px
  z-index -1

.re-register-blur-success
  background radial-gradient(55.83% 335% at 50.22% 236.67%, #15baaa 0%, rgba(255, 25, 87, 0) 100%)
  position absolute
  width 459px
  height 100px
  z-index -1

.re-switch-hover
  &:hover
    opacity 0.4
</style>
